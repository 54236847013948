<template>
<!-- 农产品统计 -->
 <div class="product_statistics">
	 <!-- 头部 -->
	 <div class="land_head">
	 	<div class="land_title">农产品统计</div>
	 	<div class="back" @click="back">
	 		<i class="el-icon-back"></i>
	 		<span style="margin-left: 4px;">返回</span>
	 	</div>
	 </div>
   <div class="charts">
	   <div class="c_top">
		   <!-- 今年各类型农产品累计入库量统计 -->
		   <div class="top_charts">
		   	   <div class="title">
				   <div class="t_l"></div>
				   <div class="t_r">
				   	<div>今年各类型农产品累计入库/出库/库存量统计</div>
				   	<div class="line">
				   		<div class="line1">
				   			<div class="line3"></div>
				   		</div>
				   		<div class="line2">
				   			<div class="line4"></div>
				   		</div>
				   	</div>
				   </div>
		   	   </div>
			   <div id="myChart_agriculturalProduct1" ref='chart_agriculturalProduct1'></div>
		   </div>
		   
	   </div>
	   <div class="c_bottom">
		   <!-- 今年各类型农产品产量 -->
		   <div class="labor_charts">
		   	   <div class="title">
				   <div class="t_l"></div>
				   <div class="t_r">
				   	<div>今年各类型农产品产量</div>
				   	<div class="line">
				   		<div class="line1">
				   			<div class="line3"></div>
				   		</div>
				   		<div class="line2">
				   			<div class="line4"></div>
				   		</div>
				   	</div>
				   </div>	   
		   	   </div>
		   	   <div id="myChart_agriculturalProduct2" ref='chart_agriculturalProduct'></div>
		   </div>
		   <!-- 今年各类型农产品销量 -->
		   <div class="labor_charts">
		   	   <div class="title">
		   		  <div class="t_l"></div>
		   		  <div class="t_r">
		   		  	<div>今年各类型农产品销量</div>
		   		  	<div class="line">
		   		  		<div class="line1">
		   		  			<div class="line3"></div>
		   		  		</div>
		   		  		<div class="line2">
		   		  			<div class="line4"></div>
		   		  		</div>
		   		  	</div>
		   		  </div>		   
		   	   </div>
		   	   <div id="myChart_agriculturalProduct3" ref='chart_agriculturalProduct'></div>
		   </div>
		   <!-- 今年各类型农产品现有库存占比 -->
		   <div class="labor_charts">
		   	   <div class="title">
		   		  <div class="t_l"></div>
		   		  <div class="t_r">
		   		  	<div>今年各类型农产品现有库存占比</div>
		   		  	<div class="line">
		   		  		<div class="line1">
		   		  			<div class="line3"></div>
		   		  		</div>
		   		  		<div class="line2">
		   		  			<div class="line4"></div>
		   		  		</div>
		   		  	</div>
		   		  </div>		   
		   	   </div>
		   	   <div id="myChart_agriculturalProduct4" ref='chart_agriculturalProduct'></div>
		   </div>
	   		  
	   </div>
   </div>
   
  

</div>
</template>

<script>
import { stock_count,stock_now_count } from '@/api/index'
// 引入所有 ECharts 中所有的图表和组件
import * as echarts from 'echarts';
export default {
  name: 'AgriculturalProductStatistics',
  data () {
    return {
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
	  timeId:[],//定时器集合
	  xAxisName:[],////所有农产品名称
	  dataObj:{
		  come:[],
		  out:[],
		  in:[],
	  }
    }
  },
  // computed:{
	 // data1:function(){
		//  let arr=[]
		// this.dataObj.come.forEach(function(e) {
		// 	arr.push(e.value) 			  
		// })
		//  console.log("arr"+arr)
		//  return	arr	 
	 // } ,
	 // data2:function(){
	 // 		 let arr=[]
	 // 		this.dataObj.out.forEach(function(e) {
	 // 			arr.push(e.value) 			  
	 // 		})
	 // 		 console.log("arr"+arr)
	 // 		 return	arr	 
	 // } ,
	 // data3:function(){
	 // 		 let arr=[]
	 // 		this.dataObj.in.forEach(function(e) {
	 // 			arr.push(e.value) 			  
	 // 		})
	 // 		 console.log("arr"+arr)
	 // 		 return	arr	 
	 // } 
  // },
  mounted () {
    // 获取chart盒子的宽度
    // this.fullWidth = `${document.querySelector('#myChart_labor1').clientWidth}`
	 this.fullWidth = document.querySelector('#myChart_agriculturalProduct1').clientWidth
	console.log( this.fullWidth)
	const that = this
	 window.onresize = () => {
	   return (() => {
	     window.fullWidth = document.querySelector('#myChart_agriculturalProduct1').clientWidth
	     that.fullWidth = window.fullWidth
	   })()
	 }
	console.log(this.fullWidth)
	this.getProfile(1) //获取入库数量
	this.getProfile(2)//获取出库数量
  },
  destroyed() {
  	console.log('destroyed')
  	console.log(this.timeId)
	clearInterval(this.onetime)
  	// 清空该页面中所有的的定时器
  	for(let i=0;i<this.timeId.length;i++){
  	   clearInterval(this.timeId[i])
  	}	
	// 销毁图表实列，释放echarts资源
	echarts.dispose(this.myChart1);
	echarts.dispose(this.myChart2);
	echarts.dispose(this.myChart3);
	echarts.dispose(this.myChart4);
	this.myChart1 = null;
	this.myChart2 = null;
	this.myChart3 = null;
	this.myChart4 = null;
  },
  methods: {
	getProfile (type) {
		console.log("获取今年出入库数量")
	  // 获取表格数据
	  const data = {
	    type: type,//1 入库  2销量（出库）
	  }
	  // 请求数据
	  stock_count(data)
	    .then((res) => {
		  console.log(res.data)
		  if(type==1){
			  this.dataObj.come=res.data
			  let arr=[]
			  this.dataObj.come.forEach(function(e) {
			  	arr.push(e.value) 			  
			  })
			  this.data1=arr
			  console.log("data1："+arr)
		  }else{
			  this.dataObj.out=res.data
			  let arr2=[]
			  this.dataObj.out.forEach(function(e) {
			  	arr2.push(e.value) 			  
			  })
			  this.data2=arr2
			   console.log("data2："+arr2)
			   this.getProfile2()//获取当前库存数量
		  }
		  // this.drawLine1()
		  // this.drawLine2()
		  // this.drawLine3()
		  // this.drawLine4()
		 
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getProfile2 () {
		console.log("获取当前库存数量")
	  // 获取表格数据
	  const data = {}
	  // 请求数据
	  stock_count(data)
	    .then((res) => {
		  console.log(res.data)
		   this.dataObj.in=res.data
		   this.xAxisName=[]
		   let that=this
		   res.data.forEach(function(item){
		   		that.xAxisName.push(item.name)
		   })
		   console.log(this.xAxisName)
		   let arr3=[]
		   this.dataObj.in.forEach(function(e) {
		   	arr3.push(e.value) 			  
		   })
		   this.data3=arr3
		    console.log("data3:"+arr3)
			this.drawLine1()
			this.drawLine2()
			this.drawLine3()
			this.drawLine4()
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	
	// 返回
	back(){
		this.$router.go(-1)
	},
	// 选择框事件，选中后去检索内容请求
	handleChange(value) {
	    console.log(value);
		console.log(this.value,this.times)
		// 更新表格内容
	},
	// 柱状图
	drawLine1() {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart1 = echarts.init(document.getElementById('myChart_agriculturalProduct1'));
			           this.myChart1.resize({
			             width: this.$refs.chart_agriculturalProduct1.clientWidth,
			             height: this.$refs.chart_agriculturalProduct1.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
					    animationDuration: 10000,
					    grid: {
					        left: '1%',
					        right: '1%',
					        bottom: '10%',
					        containLabel: true
					    },
						color: ['#00DDFF', '#FF0087', '#FFBF00'],
						 // title: {
						 //   text: 'Gradient Stacked Area Chart'
						 // },
						 tooltip: {
						   trigger: 'axis',
						   axisPointer: {
						     // type: 'cross',
							 type: 'shadow',
						     label: {
						       backgroundColor: '#6a7985',
						     }
						   }
						 },
						 legend: {
						   data: ['入库数量', '出库数量', '库存数量'],
						   x:'center',      //可设定图例在左、右、居中
						   y:'20',     //可设定图例在上、下、居中
						   textStyle:{
						       color:'#ffffff',
						     }
						 },
						 toolbox: {
						   feature: {
						 	magicType: { type: ['line', 'bar'] },
						     saveAsImage: {
						 		show:true,
						         backgroundColor :'balck',
						 		pixelRatio: 3
						 	}
						   },
						 },
					    yAxis: {
					        name:'数量',
					        nameTextStyle: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: '#fff'
					        },
					        type: 'value',
					        axisTick: {
					            show: false
					        },
					        axisLine: {
					            show: true,
					            lineStyle: {
					                color: '#535A6B',
					            },
					        },
					        splitLine: {
					            show: false,
					            lineStyle: {
					                color: '#FFFFFF',
					            }
					        },
							axisLabel: {
							    fontSize: 12,
							    fontFamily: 'Microsoft YaHei',
							    color: "#FFFFFF",
							},
					    },
					    xAxis: [
					        {
					            type: 'category',
					            axisTick: {
					                show: false
					            },
					            axisLine: {
					                show: true,
					                lineStyle: {
					                    color: '#535A6B',
					                }
					            },
					            axisLabel: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: "#FFFFFF",
					            interval: 0,
					            rotate: 20,
					            margin: 10,
					            
					        },
					            data: this.xAxisName
					        }
					    
					    ],
					    series: [
					        {
					            name: '入库数量',
					            type: 'bar',
					            itemStyle: {
					                normal: {
					                    show: true,
					                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
					                        offset: 0,
					                        color: 'rgba(0,221,255,1)'
					                    },{
					                        offset: 1,
					                        color: 'rgba(0,221,255,0.2)'
					                    }]),
					                    barBorderRadius: 50,
					                    borderWidth: 0,
					                    borderColor: '#333',
					                }
					            },
					            label: {
					                normal: {
					                    show: true,
					                    position: 'top',
					                    textStyle: {
					                        color: '#fff'
					                    }
					                }
					            },
					            barWidth: '20%',
					            data: this.data1,
					        },
							{
							    name: '出库数量',
							    type: 'bar',
							    itemStyle: {
							        normal: {
							            show: true,
							            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
							                offset: 0,
							                color: 'rgba(255,0,135,1)'
							            },{
							                offset: 1,
							                color: 'rgba(255,0,135,0.2)'
							            }]),
							            barBorderRadius: 50,
							            borderWidth: 0,
							            borderColor: '#333',
							        }
							    },
							    label: {
							        normal: {
							            show: true,
							            position: 'top',
							            textStyle: {
							                color: '#fff'
							            }
							        }
							    },
							    barWidth: '20%',
							    data: this.data2,
							},
							{
							    name: '库存数量',
							    type: 'bar',
							    itemStyle: {
							        normal: {
							            show: true,
							            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
							                offset: 0,
							                color: 'rgba(255,191,0,1)'
							            },{
							                offset: 1,
							                color: 'rgba(255,191,0,0.2)'
							            }]),
							            barBorderRadius: 50,
							            borderWidth: 0,
							            borderColor: '#333',
							        }
							    },
							    label: {
							        normal: {
							            show: true,
							            position: 'top',
							            textStyle: {
							                color: '#fff'
							            }
							        }
							    },
							    barWidth: '20%',
							    data: this.data3,
							}
					    
					    ]
					   
					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart1.setOption(option);
					   // //动态循环显示数据节点
					   this.dataLableChange(this.myChart1,option,1)
						   
	},
	// 各地出勤次数对比
	drawLine2() {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart2 = echarts.init(document.getElementById('myChart_agriculturalProduct2'));
			           this.myChart2.resize({
			             width: this.$refs.chart_agriculturalProduct.clientWidth,
			             height: this.$refs.chart_agriculturalProduct.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {

					  tooltip: {
					      trigger: 'item'
					    },
					    legend: {
					      // top: '5%',
					      // left: 'center'
						  orient: 'vertical',  //垂直显示
						  y: 'center',    //延Y轴居中
						  x: 'right' ,//居右显示
						  textStyle: {
						      color: '#fff',
							  padding:0
						  },
						  itemGap: 3, // 修改间距
						  'itemWidth':20,

					    },
						toolbox: {
						    show: true,
						    feature: {
						      // mark: { show: true },
						      // dataView: { show: true, readOnly: false },
						      // restore: { show: true },
						      saveAsImage: { show: true,backgroundColor :'balck'}
						    }
						  },
					    series: [
					      {
					        name: '产品产量',
					        type: 'pie',
					        radius: ['40%', '70%'],
							center: ["35%", "53%"], //这个属性调整图像的位置
					        avoidLabelOverlap: false,
					        // itemStyle: {
					        //   borderRadius: 10,
					        //   borderColor: '#fff',
					        //   borderWidth: 2
					        // },
					        label: {
					          show: false,
					          position: 'center'
					        },
					        emphasis: {
					          label: {
					            show: true,
					            fontSize: '18',
					            fontWeight: 'bold'
					          }
					        },
					        labelLine: {
					          show: true
					        },
					        data: this.dataObj.come
					      }
					    ]
					   
					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart2.setOption(option);
					   // //动态循环显示数据节点
					   this.dataLableChange(this.myChart2,option,2)
						   
	},
	// 各月份累计劳动量趋势图
	drawLine3() {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart3 = echarts.init(document.getElementById('myChart_agriculturalProduct3'));
			           this.myChart3.resize({
			             width: this.$refs.chart_agriculturalProduct.clientWidth,
			             height: this.$refs.chart_agriculturalProduct.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
					    animationDuration: 10000,
						tooltip: {
						    trigger: 'item'
						  },
						  legend: {
						    orient: 'vertical',  //垂直显示
						    y: 'center',    //延Y轴居中
						    x: 'right' ,//居右显示
						    textStyle: {
						        color: '#fff',
						    	padding:0
						    },
						    itemGap: 3, // 修改间距
						    'itemWidth':20,					  
						  },
						  toolbox: {
						      show: true,
						      feature: {
						        // mark: { show: true },
						        // dataView: { show: true, readOnly: false },
						        // restore: { show: true },
						        saveAsImage: { show: true,backgroundColor :'balck'}
						      }
						    },					
						  series: [
						    {
						      name: '产品销量',
						      type: 'pie',
						      radius: ['40%', '70%'],
													center: ["35%", "53%"], //这个属性调整图像的位置
						      avoidLabelOverlap: false,
						      itemStyle: {
						        borderRadius: 10,
						        borderColor: '#fff',
						        borderWidth: 2
						      },
						      label: {
						        show: false,
						        position: 'center'
						      },
						      emphasis: {
						        label: {
						          show: true,
						          fontSize: '18',
						          fontWeight: 'bold'
						        }
						      },
						      labelLine: {
						        show: true
						      },
						      data: this.dataObj.out
						    }
						  ]
					   
					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart3.setOption(option);
					   // //动态循环显示数据节点
					   this.dataLableChange(this.myChart3,option,3)
						   
	},
	// 库存占比
	drawLine4() {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart4 = echarts.init(document.getElementById('myChart_agriculturalProduct4'));
			           this.myChart4.resize({
			             width: this.$refs.chart_agriculturalProduct.clientWidth,
			             height: this.$refs.chart_agriculturalProduct.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
					    tooltip: {
					        trigger: 'item'
					      },
					      legend: {
					        orient: 'vertical',  //垂直显示
					        y: 'center',    //延Y轴居中
					        x: 'right' ,//居右显示
					        textStyle: {
					            color: '#fff',
					        	padding:0
					        },
					        itemGap: 3, // 修改间距
					        'itemWidth':20,					  
					      },
					    toolbox: {
					        show: true,
					        feature: {
					          // mark: { show: true },
					          // dataView: { show: true, readOnly: false },
					          // restore: { show: true },
					          saveAsImage: { show: true,backgroundColor :'balck'}
					        }
					      },					
					      series: [
					        {
					          name: '库存数量',
					          type: 'pie',
					          radius: ['40%', '70%'],
					    	  center: ["35%", "53%"], //这个属性调整图像的位置
					          avoidLabelOverlap: false,
					          // itemStyle: {
					          //   borderRadius: 10,
					          //   borderColor: '#fff',
					          //   borderWidth: 2
					          // },
							  roseType: 'area',
							  itemStyle: {
							    borderRadius: 8
							  },      
					          label: {
					            show: false,
					            position: 'center'
					          },
					          emphasis: {
					            label: {
					              show: true,
					              fontSize: '16',
					              fontWeight: 'bold'
					            }
					          },
					          labelLine: {
					            show: true
					          },
					          data: this.dataObj.in
					        }
					      ]
					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart4.setOption(option);
					   // //动态循环显示数据节点
					   this.dataLableChange(this.myChart,option,4)
						   
	},
	// 动态循环显示数据节点方法
	dataLableChange(myChart,option,i){
			   // console.log('shenme')
			   //动态循环显示数据节点
			   var app = {
			       currentIndex: -1,
			   };
			   this.timeId[i]=setInterval(function() {
			   	   console.log('start'+i)
			       var dataLen = option.series[0].data.length;
			       // 取消之前高亮的图形
			       myChart.dispatchAction({
			           type: 'downplay',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			       app.currentIndex = (app.currentIndex + 1) % dataLen;
			   
			       // 高亮当前图形
			       myChart.dispatchAction({
			           type: 'highlight',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex,
			       });
			   
			       // 显示 tooltip
			       myChart.dispatchAction({
			           type: 'showTip',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			   
			   }, 5000); 
			   
	},
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D+h+m+s 
	},

  },
  // 监听屏幕变化
  watch: {
    // 如果 `fullWidth ` 发生改变，这个函数就会运行
    fullWidth: function (val) {
		console.log('变化')
      if (!this.timer) {
        this.fullWidth = val
        this.drawLine1()
		this.drawLine2()
		this.drawLine3()
		this.drawLine4()
        console.log(val)
        this.timer = true
        const that = this
        setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
          that.timer = false
        }, 100)
      }
    },

  }
}
</script>
<style scoped>
  .product_statistics{
	width: 100%;
	height: 100%;
	padding:0 30px 30px 30px;
	box-sizing: border-box;
	overflow: auto;
	position: relative;
  }
  .land_head{
  	  width: 100%;
  	  height: 78px;
  	  margin-bottom: 30px;
  	  display: flex;
  	  align-items: flex-end;
  	  justify-content: start;
  	  position: relative;
  }
  .land_title{
  	  width: 554px;
  	  height: 78px;
  	  line-height: 78px;
  	  font-size: 28px;
  	  font-family: PingFang SC;
  	  /* font-weight: bold; */
  	  color: #FFFFFF;
  	  margin-left: 100px;
  	  letter-spacing: 2px;
  	  background: url(../../../assets/title_bg.png) no-repeat center;
  	  background-size: contain;
  	  position: absolute;
  	  left: 50%;
  	  transform: translateX(-50%);
  }
  .back{
  	  position: absolute;
  	  right: 0;
  	  bottom: 0;
  	  width: 135px;
  	  height: 48px;
  	  line-height: 48px;
  	  font-size: 14px;
  	  font-family: PingFang SC;
  	  letter-spacing: 2px;
  	  /* font-weight: bold; */
  	  color: #FFFFFF;
  	  background: url(../../../assets/back_bg.png) no-repeat center;
  	  background-size: cover;
  	  border: 1px solid #4B5052;
  	  display: flex;
  	  align-items: center;
  	  justify-content: center;
	  cursor: pointer;
  }
  .back i{
  	  font-size: 18px;
  }
  .title{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: flex-end;
      aljustify-content: start;
      text-align: left;
  	  margin-top: 0;
/* 	  margin-bottom: 2%; */
    }
    .t_l{
    	  width: 33px;
    	  height: 34px;
    	 background: url(../../../assets/jiankong/quan_o.png);
    	 background-size: contain;
    	  margin-right: 14px;
    }
    .t_r{
    	  width: calc(100% - 47px);
    	  font-size: 16px;
    	  font-family: PingFang SC;
    	  font-weight: bold;
    	  color: #BFF2F0;
    	  height: 42px;
    	  line-height: 42px;
    }
    .line{
    	  width: 100%;
    	  height: 1px;
    	  display: flex;
  	  justify-content: space-between;
  	  align-items: center;
    }
    .line1{
  	  width: 84%;
  	  height: 1px;
  	  background: #535A6B;
    }
    .line2{
  	  width: 15%;
  	  height: 1px;
  	  background: #535A6B;
  	  text-align: center;
    }
    .line3{
  	width: 90%;
  	height: 1px; 
  	  background: #36CFC9;
    }
    .line4{
  	  position: relative;
  	  left: 50%;
  	  transform: translateX(-50%);
  	  width: 15px;
  	  height: 1px;
  	  background: #36CFC9;
    }
	.charts{
		width: 100%;
		height: calc(100% - 108px);
	}
	.c_top,.c_bottom{
		width: 100%;
		height: calc(50% - 15px);
		/* background-color: #173660; */
		margin-bottom: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.c_bottom{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.top_charts{
		width:100%;
		height: 100%;
		background-color: rgba(10,23,56,0.7);
		padding: 0 20px 20px 20px;
		box-sizing: border-box;
	}
	.labor_charts{
		width:33% ;
		height: 100%;
		background-color: rgba(10,23,56,0.7);
		padding: 0 20px 20px 20px;
		box-sizing: border-box;
	}
	
  /deep/.el-select{
	  margin-right: 30px;
  }
  /deep/.el-select .el-input__inner{
  	  height: 48px;
  	  width: 262px;
  	  background-color: rgba(11,19,38,0.5);
  	  border: 1px solid #36CFC9;
  	  color: #FFFFFF !important;
  }
  /deep/.el-select .el-input.is-focus .el-input__inner{
	  border: 1px solid #36CFC9;
  }
  /deep/.el-input__inner{
	  height: 48px;
	  width: 262px;
	  background-color: rgba(11,19,38,0.5);
	  border: 1px solid #36CFC9;
	  color: #FFFFFF !important;
  }
  /deep/.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner{
	  border-color: #36CFC9;
  }
  /deep/.el-cascader .el-input .el-input__inner:hover, .el-range-editor.is-active, .el-range-editor.is-active:hover{
  	  border-color: #36CFC9;
  }
  /deep/.el-range-editor.el-input__inner{
	  margin-left: 30px;
  }
  /deep/.el-input__suffix{
	  color: #FFFFFF !important;
  }
 
 #myChart_agriculturalProduct1{
    width: 100%;
    height:100%;
  }
  #myChart_agriculturalProduct2{
    width: 100%;
    height:calc(100% - 30px);
   }
  #myChart_agriculturalProduct3{
     width: 100%;
     height:calc(100% - 30px);
    }
   #myChart_agriculturalProduct4{
	  width: 100%;
	  height:calc(100% - 30px);
	 }
</style>




